import { createActions } from 'redux-actions';

const {
  getMaterials,
  getMaterialsSuccess,
  getMaterialsFailure,
  getMaterialsIdle,
  getMaterialsClear,
}: {
  getMaterials?: any;
  getMaterialsSuccess?: any;
  getMaterialsFailure?: any;
  getMaterialsIdle?: any;
  getMaterialsClear?: any;
} = createActions(
  'GET_MATERIALS',
  'GET_MATERIALS_SUCCESS',
  'GET_MATERIALS_FAILURE',
  'GET_MATERIALS_IDLE',
  'GET_MATERIALS_CLEAR'
);

export {
  getMaterials,
  getMaterialsSuccess,
  getMaterialsFailure,
  getMaterialsIdle,
  getMaterialsClear,
};
