import { getPromotions, getRandomInt, setPromotions } from 'config';
import React, { useEffect, useState } from 'react';
// import Marquee from "react-marquee-slider";
import './Promotions.css';

export function Promotions() {
  // const [course, setCourse]: [any, any] = useState(0);
  // const [position, setPosition]: [any, any] = useState('');
  // const [percent, setPercent]: [any, any] = useState(0);
  // const [difference, setDifference]: [any, any] = useState(0);
  // const [title, setTitle] = useState('');
  const [listCourse, setListCourse]: [any, any] = useState();

  useEffect(() => {
    // if (!getPromotions()){

    // fetch(
    //     'https://tp-finance.linups.online/index',
    //     {
    //         method: 'GET',
    //         redirect: 'follow',
    //     }
    // )
    //     .then(response => response.json())
    //     .then(result => {

    //         console.log(result);

    //         setPromotions(JSON.stringify(result));

    //         let listCourseFirst: any = [],
    //             listCourseSecond:any = [];

    //         result.map(function(item: any, index: number){
    //             let state = '';
    //             if (item.delta && item.delta[0] == '-') {
    //                 state = 'promotions__pct--red';
    //             } else {
    //                 state = 'promotions__pct--green';
    //             }
    //             if (item.ticker == 'SBER' || item.ticker == 'VKCO' || item.ticker == 'YNDX'){
    //                 listCourseSecond.push({
    //                     title: item.name,
    //                     course: item.price,
    //                     difference: item.delta,
    //                     percent: item.percent_delta,
    //                     position: state
    //                 })
    //             } else {
    //                 listCourseFirst.push({
    //                     title: item.name,
    //                     course: item.price,
    //                     difference: item.delta,
    //                     percent: item.percent_delta,
    //                     position: state
    //                 })
    //             }
    //         });

    //         if (localStorage.getItem('show-course') == '2'){
    //             setListCourse(listCourseSecond);
    //             localStorage.setItem('show-course', '1');
    //         } else {
    //             setListCourse(listCourseFirst);
    //             localStorage.setItem('show-course', '2');
    //         }

    //     })
    //     .catch(error => {
    //         // console.log('error', error)
    //     });

    // } else {

    // let objectString = '{"value":"[{\\"ticker\\":\\"MTSS\\",\\"name\\":\\"МТС\\",\\"delta\\":-0.05,\\"price\\":215.75,\\"prev_price\\":215.8,\\"percent_delta\\":0.02},{\\"ticker\\":\\"VEON-RX\\",\\"name\\":\\"ВымпелКом\\",\\"delta\\":-0.1,\\"price\\":21.6,\\"prev_price\\":21.7,\\"percent_delta\\":0.46},{\\"ticker\\":\\"RTKM\\",\\"name\\":\\"Ростелеком\\",\\"delta\\":0.2,\\"price\\":57.31,\\"prev_price\\":57.11,\\"percent_delta\\":0.35},{\\"ticker\\":\\"SBER\\",\\"name\\":\\"Сбербанк России\\",\\"delta\\":-0.65,\\"price\\":118.8,\\"prev_price\\":119.45,\\"percent_delta\\":0.54},{\\"ticker\\":\\"VKCO\\",\\"name\\":\\"VK\\",\\"delta\\":-3,\\"price\\":437.6,\\"prev_price\\":440.6,\\"percent_delta\\":0.68},{\\"ticker\\":\\"YNDX\\",\\"name\\":\\"Yandex\\",\\"delta\\":-2.6,\\"price\\":1973.4,\\"prev_price\\":1976,\\"percent_delta\\":0.13}]","expiry":1666777196803}';

    // let result = JSON.parse(getPromotions());
    let result = JSON.parse(
      '[{"ticker":"MTSS","name":"МТС","delta":-0.05,"price":215.75,"prev_price":215.8,"percent_delta":0.02},{"ticker":"VEON-RX","name":"ВымпелКом","delta":-0.1,"price":21.6,"prev_price":21.7,"percent_delta":0.46},{"ticker":"RTKM","name":"Ростелеком","delta":0.2,"price":57.31,"prev_price":57.11,"percent_delta":0.35},{"ticker":"SBER","name":"Сбербанк России","delta":-0.65,"price":118.8,"prev_price":119.45,"percent_delta":0.54},{"ticker":"VKCO","name":"VK","delta":-3,"price":437.6,"prev_price":440.6,"percent_delta":0.68},{"ticker":"YNDX","name":"Yandex","delta":-2.6,"price":1973.4,"prev_price":1976,"percent_delta":0.13}]'
    );

    let listCourseFirst: any = [],
      listCourseSecond: any = [];

    result.map(function (item: any, index: number) {
      let state = '';
      if (item.delta && item.delta[0] == '-') {
        state = 'promotions__pct--red';
      } else {
        state = 'promotions__pct--green';
      }
      if (
        item.ticker == 'SBER' ||
        item.ticker == 'VKCO' ||
        item.ticker == 'YNDX'
      ) {
        listCourseSecond.push({
          title: item.name,
          course: item.price,
          difference: item.delta,
          percent: item.percent_delta,
          position: state,
        });
      } else {
        listCourseFirst.push({
          title: item.name,
          course: item.price,
          difference: item.delta,
          percent: item.percent_delta,
          position: state,
        });
      }
    });

    if (localStorage.getItem('show-course') == '2') {
      setListCourse(listCourseSecond);
      localStorage.setItem('show-course', '1');
    } else {
      setListCourse(listCourseFirst);
      localStorage.setItem('show-course', '2');
    }

    // }
  }, []);

  return (
    <div className="promotions promotions--mobile header__promotions">
      <div className="promotions__list">
        <div>
          {listCourse && listCourse.length > 0 && (
            // <Marquee velocity={5} direction={'ltr'} scatterRandomly={false} resetAfterTries={0} onInit={function (): void {
            //     throw new Error('Function not implemented.');
            // } } onFinish={function ({ totalTries }: { totalTries: number; }): void {
            //     throw new Error('Function not implemented.');
            // } }>
            // </Marquee>
            <>
              {listCourse.map((item: any, index: number) => {
                let { title, course, position, difference, percent } = item;
                return (
                  <div className={`promotions__item`} key={index}>
                    <div className="promotions__currency">
                      {title}:
                      <span className="promotions__currency-number">
                        {course}
                      </span>
                      <span>&#8381;</span>
                    </div>
                    <div className={`promotions__pct ${position}`}>
                      <svg className="promotions__pct-icons">
                        <use href="/img/sprite.svg#icons--arrow-currency"></use>
                      </svg>
                      <span className="promotions__pct-number">
                        {difference}
                      </span>
                      <span className="promotions__pct-sign">&#8381;</span> (
                      <span className="promotions__pct-percent">{percent}</span>{' '}
                      %)
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <div>
          {listCourse && listCourse.length > 0 && (
            // <Marquee velocity={5} direction={'ltr'} scatterRandomly={false} resetAfterTries={0} onInit={function (): void {
            //     throw new Error('Function not implemented.');
            // } } onFinish={function ({ totalTries }: { totalTries: number; }): void {
            //     throw new Error('Function not implemented.');
            // } }>
            // </Marquee>
            <>
              {listCourse.map((item: any, index: number) => {
                let { title, course, position, difference, percent } = item;
                return (
                  <div className={`promotions__item`} key={index}>
                    <div className="promotions__currency">
                      {title}:
                      <span className="promotions__currency-number">
                        {course}
                      </span>
                      <span>&#8381;</span>
                    </div>
                    <div className={`promotions__pct ${position}`}>
                      <svg className="promotions__pct-icons">
                        <use href="/img/sprite.svg#icons--arrow-currency"></use>
                      </svg>
                      <span className="promotions__pct-number">
                        {difference}
                      </span>
                      <span className="promotions__pct-sign">&#8381;</span> (
                      <span className="promotions__pct-percent">{percent}</span>{' '}
                      %)
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
      {/* {type !== -1 && (
                <>
                    <div className="promotions__currency">
                        {title}:
                        <span className="promotions__currency-number">
                            {course}
                        </span>
                        <span>&#8381;</span>
                    </div>
                    <div className={`promotions__pct ${position}`}>
                        <svg className="promotions__pct-icons">
                            <use href="/img/sprite.svg#icons--arrow-currency"></use>
                        </svg>
                        <span className="promotions__pct-number">
                            {difference}
                        </span>
                        <span className="promotions__pct-sign">&#8381;</span> (
                        <span className="promotions__pct-percent">
                            {percent}
                        </span>{' '}
                        %)
                    </div>
                </>
            )} */}
    </div>
  );
}
