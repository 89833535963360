import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import {
  registerUser,
  registerUserFailure,
  registerUserIdle,
  registerUserSuccess,
  //registerSetToken,
} from './actions';

import { StateStatus } from 'types/models';

import { RegisterState } from './types';

export const initialState: RegisterState = {
  status: 'IDLE',
  messages: {},
};

/*const token = handleActions(
    {
        [registerSuccess]: (state, action) => action.payload,
        [registerUserFailure]: (state, action) => action.payload,
        [registerSetToken]: (state, action) => action.payload,
    },
    initialState.token
);*/

const status = handleActions<StateStatus>(
  {
    [registerUser]: () => 'LOADING',
    [registerUserSuccess]: () => 'SUCCESS',
    [registerUserFailure]: () => 'FAILURE',
    [registerUserIdle]: () => 'IDLE',
  },
  initialState.status
);

const messages = handleActions(
  {
    [registerUserSuccess]: (state, action) => action.payload,
  },
  initialState.messages
);

export const userRegister = combineReducers({
  status,
  messages,
});
