import React, { useEffect } from 'react';
import './Person.css';

import { useDispatch, useSelector } from 'react-redux';
import { getPerson } from 'store/ducks/person/actions';

import { MoreArrow, PostPerson } from 'components';
import { pathPersony } from 'config';
import { personsSelector } from 'store/ducks/homepage/selectors/personsSelector';

export function Person() {
  const dispatch = useDispatch();

  const personList = useSelector(personsSelector);
  const status = useSelector(
    (state: { homepage: { status: string } }) => state.homepage.status
  );

  // useEffect(() => {
  //     if (status == 'IDLE') {
  //         dispatch(getPerson());
  //     }
  // }, []);

  return (
    <section className="person page__person">
      <h2 className="page-title person__page-title">Персоны</h2>
      <MoreArrow type="person" link={pathPersony}>
        Все персоны
      </MoreArrow>
      {personList &&
        personList.length > 0 &&
        personList.map(item => <PostPerson {...item} />)}
    </section>
  );
}
