import React, { useState, useEffect } from 'react';
import './Authors.css';
import cx from 'classnames';
import { Tag, MoreArrow } from 'components';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthors, getAuthorsIdle } from 'store/ducks/authors/actions';
import moment from 'moment';

import { Pagination } from 'antd';
import { Link } from 'react-router-dom';

const options = [
  { value: 'date', label: 'По дате' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
];

export function Authors() {
  const [selectedOption, setSelectedOption] = useState(null);

  const dispatch = useDispatch();

  const { status, list, total_pages, curret_page } = useSelector(
    (state: {
      authors: {
        status: any;
        list: any;
        total_pages: number;
        curret_page: number;
      };
    }) => state.authors
  );

  useEffect(() => {
    if (status == 'IDLE') {
      dispatch(
        getAuthors({
          page: (curret_page && curret_page) || 1,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (status == 'SUCCESS') {
      dispatch(getAuthorsIdle());
    }
  }, [status]);

  return (
    <div className="authors">
      <h2 className="authors__title">Авторы</h2>
      {/* <div className="authors__wrapper">
                <div className="search search--authors authors__search">
                    <form className="search__form" action="#">
                        <input
                            className="search__input"
                            type="text"
                            placeholder="Поиск по автору, должности или названию статьи"
                        />
                        <button className="search__btn" type="submit">
                            <svg className="search__icon">
                                <use href="/img/sprite.svg#icons--search"></use>
                            </svg>
                        </button>
                    </form>
                </div>
                <Select
                    // defaultValue={{ label: "По дате", value: 0 }}
                    onChange={setSelectedOption}
                    options={options}
                />
            </div> */}
      <div className="authors-list authors__list">
        {list.length > 0 &&
          list.map((item: any) => {
            let {
              avatar,
              username,
              surname,
              id,
              description,
              email,
              posts,
            } = item;
            return (
              <div className="author-item authors-list__item" key={id}>
                <div className="author-item__header">
                  <div className="author-item__wrap-img">
                    {avatar && (
                      <img
                        src={avatar}
                        alt="img"
                        className="author-item__img"
                      />
                    )}
                  </div>
                  <h4 className="author-item__title">
                    {surname} {username}
                  </h4>
                  <span className="author-item__subtitle">
                    {/* {email}
                                    <br/> */}
                    {description}
                  </span>
                </div>
                <div className="author-item__body">
                  {posts &&
                    posts.length > 0 &&
                    posts.map((item: any) => {
                      return (
                        <article
                          key={item.id}
                          className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed"
                        >
                          <Link
                            to={'/' + item.categories_path + '/' + item.slug}
                          >
                            <h3 className="post-newsfeed__title">
                              {item.title}
                            </h3>
                            <div className="post-newsfeed__subtitle">
                              {' '}
                              <span className="post-newsfeed__date">
                                {moment(item.publication_at).format(
                                  'DD.MM.YYYY'
                                )}
                              </span>{' '}
                              |
                              <span className="post-newsfeed__time">
                                {moment(item.publication_at).format('HH:MM')}
                              </span>
                            </div>
                          </Link>
                        </article>
                      );
                    })}
                  {posts && posts.length >= 3 && (
                    <Link
                      to={'/magazine/authors/' + id}
                      className="more more--purple authors-item__more"
                    >
                      Все статьи
                      <svg className="more__icon">
                        <use href="/img/sprite.svg#icons--arrow-right"></use>
                      </svg>
                    </Link>
                  )}
                </div>
              </div>
            );
          })}

        {/* <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-2.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">
                            Барсков Александр
                        </h4>
                        <span className="author-item__subtitle">
                            директор по контенту «ИКС-холдинг»
                        </span>
                    </div>
                </div>
                <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-3.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">Бедрань Владимир</h4>
                        <span className="author-item__subtitle">
                            старший вице-президент Viacom
                        </span>
                    </div>
                    <div className="author-item__body">
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Аналитика рынков онлайн-видео и платного ТВ
                                    в США и Канады за II квартал 2017 года
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    4К: дороги, которыми России еще только
                                    предстоит пройти
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Аналитики понизили прогнозируемый доход
                                    платного ТВ в регионе MENAдо $4 млрд
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                    </div>
                </div>
                <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-4.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">Безруков Андрей</h4>
                        <span className="author-item__subtitle">
                            старший вице-президент Viacom
                        </span>
                    </div>
                    <div className="author-item__body">
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Комплексная программа подготовки кадров —
                                    стратегический приоритет GS Group
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                    </div>
                </div>
                <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-5.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">Белоусов Юрий</h4>
                        <span className="author-item__subtitle">
                            директор ООО «Меццо»
                        </span>
                    </div>
                    <div className="author-item__body">
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    «Большой шелковый путь» цифровых приставок
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                    </div>
                </div>
                <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-6.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">Бердан Владимир</h4>
                        <span className="author-item__subtitle">
                            директор по контенту «ИКС-холдинг»
                        </span>
                    </div>
                </div>
                <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-7.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">Бителева Анна</h4>
                        <span className="author-item__subtitle">
                            старший вице-президент Viacom
                        </span>
                    </div>
                    <div className="author-item__body">
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Post-NAB о тенденциях в телевидении
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Стандарт DVB-I
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Как WISI за 93 года прошла путь от дюбелей
                                    до OTT и IPTV
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    Как телевидение осваивает искусственный
                                    интеллект
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                    </div>
                </div>
                <div className="author-item authors-list__item">
                    <div className="author-item__header">
                        <div className="author-item__wrap-img">
                            <img
                                src="/img/authors-item-img-8.jpg"
                                alt="img"
                                className="author-item__img"
                            />
                        </div>
                        <h4 className="author-item__title">Блинов Вадим</h4>
                        <span className="author-item__subtitle">
                            менеджер продукта Elecard CodecWorks
                        </span>
                    </div>
                    <div className="author-item__body">
                        <article className="post-newsfeed post-newsfeed--author-item author-item__post-newsfeed">
                            <a href="#">
                                <h3 className="post-newsfeed__title">
                                    QuickSync против NVENC: сравнение
                                    кодирования на GPU
                                </h3>
                                <div className="post-newsfeed__subtitle">
                                    {' '}
                                    <span className="post-newsfeed__date">
                                        11.03.2021
                                    </span>{' '}
                                    |
                                    <span className="post-newsfeed__time">
                                        10:25
                                    </span>
                                </div>
                            </a>
                        </article>
                    </div>
                </div> */}
        {total_pages > 1 && (
          <div className="pagination authors-list__pagination">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();

                let currentPage = curret_page - 1;

                if (curret_page < 2) {
                  currentPage = total_pages;
                }

                if (status == 'IDLE') {
                  dispatch(
                    getAuthors({
                      page: currentPage,
                    })
                  );
                }
              }}
            >
              &#60;
            </a>
            {Array.from(Array(total_pages).keys()).map(item => {
              let numberPage = item + 1;
              return (
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();

                    if (status == 'IDLE') {
                      dispatch(
                        getAuthors({
                          page: numberPage,
                        })
                      );
                    }
                  }}
                  className={numberPage == curret_page ? 'active' : ''}
                >
                  {numberPage}
                </a>
              );
            })}
            {/*  */}
            {/* <a href="#" className="active">1</a>
                        <a href="#">
                            2
                        </a>
                        <a href="#">3</a>
                        <a href="#">4</a>
                        <a href="#">5</a>
                        <a href="#">6</a>
                        <a href="#">7</a>*/}
            <a
              href="#"
              onClick={e => {
                e.preventDefault();

                let currentPage = curret_page + 1;

                if (curret_page >= total_pages) {
                  currentPage = 1;
                }

                if (status == 'IDLE') {
                  dispatch(
                    getAuthors({
                      page: currentPage,
                    })
                  );
                }
              }}
            >
              &#62;
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
