import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getHomeRequest = (payload: { type: string }) => {
  const { type } = payload;

  const url = prefixApiUrl + 'api/home/get-posts/' + type;

  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => data);
};
