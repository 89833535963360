import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, SubmitHandler } from 'react-hook-form';
import { articleCommentsSelector } from 'store/ducks/comments/selectors';
import { getCommentsArticle } from 'store/ducks/comments/actions/commentsActions';
import {
  addCommentsArticle,
  addCommentsArticleIdle,
} from 'store/ducks/comments/actions/addCommentsActions';
import { useParams } from 'react-router-dom';
import { Likes } from '../../components';
import { StateStatus } from 'types/models';
import './Comments.css';

import { Comment } from './Components/Comment';

type Props = {
  postId: any;
  newPost: any;
  setStatus: any;
};

type Inputs = {
  example: string;
  text: string;
};

export function Comments(props: Props) {
  const { postId, newPost, setStatus } = props;
  const params: { id: any } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm<Inputs>();

  const data: any = useSelector(articleCommentsSelector);
  const dispatch = useDispatch();

  const { status } = useSelector(
    (state: { comments: { status: StateStatus } }) => state.comments
  );
  const { statusAdd } = useSelector(
    (state: { comments: { statusAdd: StateStatus } }) => state.comments
  );
  const token = useSelector(
    (state: { userAuth: { token: boolean } }) => state.userAuth.token
  );

  useEffect(() => {
    if (status == 'IDLE') {
      dispatch(getCommentsArticle({ postId, status }));
    }
  }, []);

  useEffect(() => {
    if (statusAdd === 'SUCCESS') {
      resetField('text');
      dispatch(getCommentsArticle({ postId, status }));
      setTimeout(function () {
        dispatch(addCommentsArticleIdle());
      }, 2000);
    }
  }, [statusAdd]);

  useEffect(() => {
    if (newPost === true) {
      if (status == 'IDLE') {
        dispatch(getCommentsArticle({ postId, status }));
      }
      setStatus(false);
    }
  }, [newPost]);

  const onSubmit: SubmitHandler<Props> = data => {
    if (statusAdd == 'IDLE') {
      dispatch(addCommentsArticle({ postId, status, data, token }));
    }
  };

  return (
    <div className="article-comments article-footer__article-comments">
      <div className="article-comments__header">
        <h3 className="article-comments__title">Комментарии</h3>
        <a href="#" className="article-comments__count">
          ({data.length})
        </a>
      </div>
      <div className="article-comments__form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <textarea
            className="article-comments__form-input"
            placeholder="Введите комментарий"
            {...register('text', { required: true })}
          ></textarea>
          {errors.text && <span>Заполните поле</span>}
          {statusAdd === 'SUCCESS' && (
            <span className="article-comments__alert">
              Комментарий отправлен на модерацию
            </span>
          )}
          <button className="btn article-comments__form-btn" type="submit">
            Отправить
          </button>
        </form>
      </div>
      <div className="article-comments__list">
        <ul className="article-comments__list">
          {data &&
            data.length > 0 &&
            data.map((item: any) => (
              <Comment {...item} />
              // <li
              //     className="comment article-comments__item"
              //     key={item.key}
              // >
              //     <div className="comment__img">
              //         <img src={item.avatar} alt="img" />
              //     </div>
              //     <div className="comment__content">
              //         <div className="comment__header">
              //             <div className="comment__name">
              //                 {item.fio}
              //             </div>
              //             <div className="comment__date">
              //                 {item.date}
              //             </div>
              //         </div>
              //         <div className="comment__text">
              //             {item.text}
              //         </div>
              //         <Likes
              //             postId={item.id}
              //             like={item.like}
              //             dislike={item.dislike}
              //             type="comments"
              //         />
              //     </div>
              // </li>
            ))}
        </ul>
      </div>
    </div>
  );
}
