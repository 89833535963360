import { createActions } from 'redux-actions';

const {
  getHomepage,
  getHomepageSuccess,
  getHomepageFailure,
  getHomepageIdle,
  getMaterialsLoadMore,
  getMaterialsLoadMoreSuccess,
  getMaterialsLoadMoreFailure,
  getMaterialsLoadMoreIdle,
  getMaterialsLoadMoreClear,
}: {
  getHomepage?: any;
  getHomepageSuccess?: any;
  getHomepageFailure?: any;
  getHomepageIdle?: any;
  getMaterialsLoadMore?: any;
  getMaterialsLoadMoreSuccess?: any;
  getMaterialsLoadMoreFailure?: any;
  getMaterialsLoadMoreIdle?: any;
  getMaterialsLoadMoreClear?: any;
} = createActions(
  'GET_HOMEPAGE',
  'GET_HOMEPAGE_SUCCESS',
  'GET_HOMEPAGE_FAILURE',
  'GET_HOMEPAGE_IDLE',
  'GET_MATERIALS_LOAD_MORE',
  'GET_MATERIALS_LOAD_MORE_SUCCESS',
  'GET_MATERIALS_LOAD_MORE_FAILURE',
  'GET_MATERIALS_LOAD_MORE_IDLE',
  'GET_MATERIALS_LOAD_MORE_CLEAR'
);

export {
  getHomepage,
  getHomepageSuccess,
  getHomepageFailure,
  getHomepageIdle,
  getMaterialsLoadMore,
  getMaterialsLoadMoreSuccess,
  getMaterialsLoadMoreFailure,
  getMaterialsLoadMoreIdle,
  getMaterialsLoadMoreClear,
};

// import { Sneakers } from 'types';
// import * as types from './types';

// action creators
// export function fetchHomepage(): types.FetchHomepageAction {
//     return { type: types.FETCH_HOMEPAGE };
// }

// export function fetchHomepageSuccess(data: {
//     popular: any[];
//     newest: any[];
// }): types.FetchHomepageSuccessAction {
//     return { type: types.FETCH_HOMEPAGE_SUCCESS, payload: data };
// }

// export function fetchHomepageError(
//     error: string
// ): types.FetchHomepageFailureAction {
//     return { type: types.FETCH_HOMEPAGE_FAILURE, payload: error };
// }
