import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getTagsRequest = () => {
  // const url = prefixApiUrl + 'api/taxonomyitem/index/10';
  const url =
    prefixApiUrl + 'api/taxonomyitem/index/10/publication_at/DESC/1/10';

  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => data);
};
