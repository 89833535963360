import { getMetaField, prefixApiUrl } from 'config';
import { createSelector } from 'reselect';
import moment from 'moment';
import { Comments } from 'types/models';

const comments = (state: any) => state.comments.items;

export const articleCommentsSelector = createSelector([comments], items => {
  let data: any[] = [];

  if (items && items.length) {
    items.map((item: Comments) => {
      data.push({
        id: item.id,
        key: item.id,
        like: item.likes_count,
        dislike: item.dislikes_count,
        fio: item.user.username + ' ' + item.user.surname,
        avatar: item.user.avatar
          ? `${prefixApiUrl}storage/${item.user.avatar}`
          : '/img/no-avatar-comment.png',
        text: item.text,
        date:
          moment(item.publication_at).format('DD MMMM YYYY') !=
          moment().format('DD MMMM YYYY')
            ? moment(item.publication_at).locale('ru').format('DD MMMM')
            : moment(item.publication_at).format('HH:mm'),
      });
    });
  }

  return data;
});
