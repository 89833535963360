import React, { useEffect } from 'react';
import './ArticleSubpost.css';
import { Tag, VideoIcon } from 'components';
import { Link } from 'react-router-dom';
import cx from 'classnames';
type Props = {
  dopClass: string;
  id: string;
  link: string;
  img: string;
  title: string;
  tag: string;
  type: string;
  date: string;
  position?: string;
  isBanner?: boolean;
};
declare global {
  interface Window {
    ga?: any;
    gtag?: any;
  }
}
export function ArticleSubpost(props: Props) {
  const {
    id,
    link,
    img,
    title,
    tag,
    type,
    date,
    isBanner,
    dopClass,
    position,
  } = props;
  useEffect(() => {
    if (isBanner) {
      window.gtag &&
        window.gtag('event', 'banner', {
          action: 'view',
          id: position + '_' + id,
        });
    }
  }, []);
  let handleClick = () => {
    window.gtag &&
      window.gtag('event', 'banner', {
        action: 'click',
        id: position + '_' + id,
      });
  };
  return (
    <article className={`subpost ${dopClass ? dopClass : ''}`} key={id}>
      {(isBanner && (
        <a
          href={link}
          className={cx('subpost__link', '_banner', position + '_' + id)}
          onClick={handleClick}
          target={'_blank'}
        >
          <img src={img} alt="img" className="subpost__img" />
        </a>
      )) || (
        <Link
          to={link}
          className={`subpost__link ${isBanner ? '_banner' : ''}`}
          target={isBanner ? '_blank' : ''}
        >
          <img src={img} alt="img" className="subpost__img" />
          {!isBanner && (
            <>
              <div className="subpost__common">
                <h3
                  className="subpost__title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
                <div className="subpost__date">{date}</div>
              </div>
              <Tag mod="subpost" type={type}>
                {tag}
              </Tag>
            </>
          )}
          {tag === 'Видео' && <VideoIcon mod="subpost" />}
        </Link>
      )}
    </article>
  );
}
