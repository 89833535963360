import { prefixApiUrl, token } from 'config';
import { error } from 'console';
import fetch from 'isomorphic-fetch';
import { type } from 'os';

export const getNewsfeedsRequest = (payload: {
  taxonomy: string;
  type: string;
  page: number;
  offset: number;
  offsetCount: number;
}) => {
  let { taxonomy, type, page, offset, offsetCount } = payload;
  let count = 6;
  if (!offset) {
    offset = 0;
  }
  if (!offsetCount) {
    offsetCount = 18;
  }
  if (!taxonomy) {
    taxonomy = '0';
  }

  if (!type) {
    type = '0';
  }

  if (taxonomy == '0' && type == '0') {
    if (!page) {
      page = 1;
      count = 12;
    } else {
      page = page + 2;
    }
  } else {
    if (!page) {
      page = 1;
      count = 18;
    } else {
      page = page + 3;
    }
  }

  // let url =
  //     prefixApiUrl +
  //     'api/taxonomyitem/posts/' +
  //     taxonomy +
  //     '/' +
  //     type +
  //     '/created_at/DESC/' +
  //     page +
  //     '/' +
  //     count;

  // type-content
  // materials
  // tag
  // persons

  let slugTaxonomy = 'materials';

  if (taxonomy == 'persony' && type !== '0') {
    slugTaxonomy = 'persons';
    taxonomy = type;
  }

  let url =
    prefixApiUrl +
    'api/taxonomyitem/postsbyslug/' +
    slugTaxonomy +
    '/' +
    taxonomy +
    '/content/publication_at/DESC/' +
    page +
    '/' +
    count;

  if (taxonomy == 'tag') {
    slugTaxonomy = 'tag';
    taxonomy = type;
  }

  if (slugTaxonomy == 'materials') {
    url =
      prefixApiUrl +
      'api/taxonomyitem/postsbyslugoffsetlimit/materials/' +
      taxonomy +
      '/content/publication_at/DESC/' +
      offset +
      '/' +
      offsetCount;
  }

  if (taxonomy == 'all') {
    url =
      prefixApiUrl +
      'api/taxonomyitem/postsbyslugoffsetlimit/type-content/' +
      type +
      '/content/publication_at/DESC/' +
      offset +
      '/' +
      offsetCount;
  }

  if (slugTaxonomy == 'tag') {
    url =
      prefixApiUrl +
      'api/taxonomyitem/postsbyslugoffsetlimit/tag/' +
      taxonomy +
      '/content/publication_at/DESC/' +
      offset +
      '/' +
      offsetCount;
  }

  // if (taxonomy == '0' && type == '0') {
  //     url =
  //         prefixApiUrl +
  //         'api/taxonomy/7/posts/created_at/DESC/' +
  //         page +
  //         '/' +
  //         count;
  // }

  // if (taxonomy == 'persony') {
  //     url =
  //         prefixApiUrl +
  //         'api/person/posts/' +
  //         type +
  //         '/created_at/DESC/' +
  //         page +
  //         '/' +
  //         count;
  // }

  // if(type == '0' && taxonomy){
  //     url = prefixApiUrl + 'api/taxonomyitem/postsbyslug/' + taxonomy + '/created_at/DESC/' + page + '/' + count;
  // }
  // if (type == 'tag') {
  //     url =
  //         prefixApiUrl +
  //         'api/taxonomyitem/postsbyslug/' +
  //         taxonomy +
  //         '/created_at/DESC/' +
  //         page +
  //         '/' +
  //         count;
  // }

  // if (type == 'author') {
  //     url =
  //         prefixApiUrl +
  //         'api/user/posts-author/' +
  //         taxonomy +
  //         '/id/DESC/' +
  //         page +
  //         '/' +
  //         count;
  // }

  if (taxonomy == '0' && type == '0') {
    url =
      prefixApiUrl +
      'api/taxonomy/7/posts/publication_at/DESC/' +
      page +
      '/' +
      count;
  }
  if (type == '0' && taxonomy == 'all') {
    url =
      prefixApiUrl +
      'api/taxonomy/7/posts/publication_at/DESC/' +
      page +
      '/' +
      count;

    url =
      prefixApiUrl +
      'api/post/indexoffsetlimit/content/publication_at/DESC/' +
      offset +
      '/' +
      offsetCount;
  }

  //

  return new Promise((resolve, reject) => {
    const headers = {
      // Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };
    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => reject(error));
  });
};
