import { createActions } from 'redux-actions';

const {
  getInitdata,
  getInitdataSuccess,
  getInitdataFailure,
  getInitdataIdle,
}: {
  getInitdata?: any;
  getInitdataSuccess?: any;
  getInitdataFailure?: any;
  getInitdataIdle?: any;
} = createActions(
  'GET_INITDATA',
  'GET_INITDATA_SUCCESS',
  'GET_INITDATA_FAILURE',
  'GET_INITDATA_IDLE'
);

export { getInitdata, getInitdataSuccess, getInitdataFailure, getInitdataIdle };
