import { call, takeEvery, put, all } from 'redux-saga/effects';

import {
  getArticleRequest,
  getArticleListRequest,
  getArticleIDRequest,
  setViewRequest,
} from './api';

import { is404, is404Idle } from 'store/ducks/404/actions';
import {
  getArticle,
  getArticleFailure,
  getArticleSuccess,
} from './actions/articleActions';

import {
  getArticleList,
  getArticleListFailure,
  getArticleListSuccess,
} from './actions/articleListActions';
import { hashCode, setView, getView, prefixApiUrl } from 'config';

function* getSaga(action: any) {
  try {
    const { payload } = action;

    const result: { slug: any; id: any } = yield call(
      getArticleRequest,
      payload
    );
    if (result.id) {
      let hashCodeString = result.id;

      if (!getView(hashCodeString)) {
        setView(hashCodeString);

        setViewRequest({ id: result.id, hash: 'post-view' });
      }
      yield put(getArticleSuccess(result));
    } else {
      yield put(getArticleFailure());
    }
  } catch (error) {
    console.log(error);
    yield put(getArticleFailure(error));
  }
}

function* getSagaAddList(action: any) {
  try {
    const { payload } = action;

    const result: { slug: any; id: any } = yield call(
      getArticleRequest,
      payload
    );

    let hashCodeString = result.id;

    if (!getView(hashCodeString)) {
      setView(hashCodeString);

      setViewRequest({ id: result.id, hash: 'post-view' });
    }

    yield put(getArticleListSuccess(result));
  } catch (error) {
    // console.log(error);
    yield put(getArticleListFailure(error));
  }
}

// function* getSagaAddListTotalPages(action: { payload: { page: number } }) {
//     try {
//         const { payload } = action;
//         const result: [] = yield call(
//             getArticleListRequest,
//             payload
//         );
//         yield put(getArticleListSuccess(result));
//     } catch (error) {
//         console.log(error);
//         yield put(getArticleListFailure(error));
//     }
// }

export function* articleWatcher() {
  yield takeEvery(getArticle.toString(), getSaga);
  yield takeEvery(getArticleList.toString(), getSagaAddList);
}
