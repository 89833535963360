import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const restoreRequest = (payload: { email: string }) => {
  let { email } = payload;

  let url = prefixApiUrl + 'api/forgot-password';

  const headers = {
    // Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  const body = {
    email,
  };

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then(response => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      // console.log('restore error', error)
    });
};
