import * as React from 'react';
import './FooterSocial.css';

export function FooterSocial() {
  return (
    <div className="footer-social footer__social">
      <h4 className="footer-social__title">
        {/* Партнерам */}
        &nbsp;
      </h4>
      <ul className="footer-social__list">
        <li className="footer-social__item">
          <a
            href="https://telegram.me/telesputnik"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--telegram">
              <use href="/img/sprite.svg#icons--telegram"></use>
            </svg>
          </a>
        </li>
        <li className="footer-social__item">
          <a
            href="mailto:telesputnik@telesputnik.ru"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--mail">
              <use href="/img/sprite.svg#icons--mail"></use>
            </svg>
          </a>
        </li>
        <li className="footer-social__item">
          <a
            href="https://vk.com/telesputnik"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--vk">
              <use href="/img/sprite.svg#icons--vk"></use>
            </svg>
          </a>
        </li>
        <li className="footer-social__item">
          <a
            href="https://dzen.ru/telesputnik"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--zen">
              <use href="/img/sprite.svg#icons--zen"></use>
            </svg>
          </a>
        </li>
        {/* <li className="footer-social__item">
                    <a href="#" className="footer-social__link">
                        <svg className="footer-social__icon footer-social__icon--facebook">
                            <use href="/img/sprite.svg#icons--facebook"></use>
                        </svg>
                    </a>
                </li> */}
        <li className="footer-social__item">
          <a
            href="https://ok.ru/group/55408711696413"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--odn">
              <use href="/img/sprite.svg#icons--odn"></use>
            </svg>
          </a>
        </li>
        <li className="footer-social__item">
          <a
            href="https://rutube.ru/channel/27553574"
            target="_blank"
            className="footer-social__link"
          >
            <img width="24px" src="/img/icon-rutube.svg" />
          </a>
        </li>
        <li className="footer-social__item">
          <a
            href="https://www.youtube.com/channel/UCUZZFbgpPMOdNF8-S6Kj4Cg"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--youtube">
              <use href="/img/sprite.svg#icons--youtube"></use>
            </svg>
          </a>
        </li>
        <li className="footer-social__item">
          <a
            href="http://www.telesputnik.ru/rss/"
            target="_blank"
            className="footer-social__link"
          >
            <svg className="footer-social__icon footer-social__icon--rrs">
              <use href="/img/sprite.svg#icons--rrs"></use>
            </svg>
          </a>
        </li>
        {/* <li className="footer-social__item">
                    <a href="#" className="footer-social__link">
                        <svg className="footer-social__icon footer-social__icon--instagram">
                            <use href="/img/sprite.svg#icons--instagram"></use>
                        </svg>
                    </a>
                </li>
                <li className="footer-social__item">
                    <a href="#" className="footer-social__link">
                        <svg className="footer-social__icon footer-social__icon--twitter">
                            <use href="/img/sprite.svg#icons--twitter"></use>
                        </svg>
                    </a>
                </li> */}
      </ul>
    </div>
  );
}
