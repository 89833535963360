import React, { useEffect, useState } from 'react';
import './Main.css';

import { useSelector } from 'react-redux';

import { ArticlePost, ArticleSubpost } from 'components';
import { topMaterialsSelector } from 'store/ducks/homepage/selectors/topMaterialsSelector';
import {
  selectPosition17Banner,
  selectPosition18Banner,
} from 'store/ducks/banners/selectors';

export function Main() {
  const data = useSelector(topMaterialsSelector);

  const position17Banner = useSelector(selectPosition17Banner);
  const position18Banner = useSelector(selectPosition18Banner);

  return (
    <section className="main page__main">
      {data.single && <ArticlePost {...data.single} />}
      {data.list && data.list.length > 0 && (
        <div className="main__subpost-list">
          {data.list.map((item, index) => {
            if (index == 0 && position17Banner) {
              let banner = {
                dopClass: '_banner',
                id: position17Banner.id,
                link: position17Banner.url,
                img: position17Banner.img,
                title: '',
                tag: '',
                type: '',
                date: '',
                isBanner: true,
                position: 'position17Banner',
              };
              return <ArticleSubpost key={index} {...banner} />;
            } else if (index == 2 && position18Banner) {
              let banner = {
                dopClass: '_banner',
                id: position18Banner.id,
                link: position18Banner.url,
                img: position18Banner.img,
                title: '',
                tag: '',
                type: '',
                date: '',
                isBanner: true,
                position: 'position18Banner',
              };
              return <ArticleSubpost key={index} {...banner} />;
            } else {
              return <ArticleSubpost key={index} {...item} />;
            }
          })}
          {data.list.length < 3 && position18Banner && (
            <ArticleSubpost
              dopClass={'_banner'}
              key={2}
              id={position18Banner.id}
              link={position18Banner.url}
              img={position18Banner.img}
              position="position18Banner"
              title={''}
              tag={''}
              type={''}
              date={''}
              isBanner={true}
            />
          )}
        </div>
      )}
    </section>
  );
}
