import * as React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from 'types/models';
import './Menu.css';

type Props = {
  menu: {
    visible: MenuItem[];
    hidden: MenuItem[];
  };
};

export function Menu(props: Props) {
  const { menu } = props;

  return (
    <nav className="menu menu--mobile header__menu">
      <ul className="menu__list">
        {menu.visible &&
          menu.visible.length > 0 &&
          menu.visible.map((item: MenuItem, index: number) => {
            return (
              <li className="menu__item" key={index}>
                <Link to={item.slug} className="menu__link">
                  {item.title}
                </Link>
              </li>
            );
          })}
        <li className="menu__item">
          <span
            // href="#"
            className="menu__link menu__link--arrow"
          >
            Ещё
          </span>
          <div className="submenu-items">
            <ul className="submenu-list">
              {menu.hidden &&
                menu.hidden.length > 0 &&
                menu.hidden.map((item: MenuItem, index: number) => {
                  return (
                    <li className="submenu-items__item" key={index}>
                      <Link to={item.slug} className="submenu-items__link">
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </div>
        </li>
      </ul>
    </nav>
  );
}
