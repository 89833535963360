import { createActions } from 'redux-actions';

const {
  getCommentsArticle,
  getCommentsArticleSuccess,
  getCommentsArticleFailure,
  getCommentsArticleIdle,
}: {
  getCommentsArticle?: any;
  getCommentsArticleSuccess?: any;
  getCommentsArticleFailure?: any;
  getCommentsArticleIdle?: any;
} = createActions(
  'GET_COMMENTS_ARTICLE',
  'GET_COMMENTS_ARTICLE_SUCCESS',
  'GET_COMMENTS_ARTICLE_FAILURE',
  'GET_COMMENTS_ARTICLE_IDLE'
);

export {
  getCommentsArticle,
  getCommentsArticleSuccess,
  getCommentsArticleFailure,
  getCommentsArticleIdle,
};
