import { createSelector } from 'reselect';

import { Post } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const eventsFirst = (state: { homepage: { events: Post[] } }) =>
  state.homepage.events;

export const materialsLoadMoreFirstSelector = createSelector(
  [eventsFirst],
  items => {
    let data: any[] = [];

    if (items && items.length) {
      items.map((item: Post, index: number) => {
        if (item) {
          let dateArticle = item.created_at;
          if (item.publication_at) {
            dateArticle = item.publication_at;
          }
          let object = {
            id: item.id,
            link: item.categories_path + '/' + item.slug,
            img: getPreview(
              item.get_post_types,
              item.announce_img,
              null,
              true,
              moment(dateArticle)
            ),
            title:
              item.title + '<br/>' + moment(dateArticle).format('DD.MM.YYYY'),
          };

          data.push(object);
        }
      });
    }

    return data;
  }
);
