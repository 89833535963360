import { createActions } from 'redux-actions';

const {
  addSubscribe,
  addSubscribeSuccess,
  addSubscribeFailure,
  addSubscribeIdle,
}: {
  addSubscribe?: any;
  addSubscribeSuccess?: any;
  addSubscribeFailure?: any;
  addSubscribeIdle?: any;
} = createActions(
  'ADD_SUBSCRIBE',
  'ADD_SUBSCRIBE_SUCCESS',
  'ADD_SUBSCRIBE_FAILURE',
  'ADD_SUBSCRIBE_IDLE'
);

export {
  addSubscribe,
  addSubscribeSuccess,
  addSubscribeFailure,
  addSubscribeIdle,
};
