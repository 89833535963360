import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getAuthorsRequest = (payload: any) => {
  const { page } = payload;

  const url = prefixApiUrl + 'api/user/get-authors/id/desc/' + page + '/8';

  const headers = {
    Authorization: 'Bearer ',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => data);
};
