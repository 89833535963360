import { createSelector } from 'reselect';

import { Post } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const articlesList = (state: { homepage: { articlesList: Post[] } }) =>
  state.homepage.articlesList;

export const articlesListSelector = createSelector([articlesList], items => {
  let data: any[] = [];

  if (items && items.length) {
    items.map((item: Post, index: number) => {
      if (item) {
        let dateArticle = item.created_at;
        if (item.publication_at) {
          dateArticle = item.publication_at;
        }

        let object = {
          id: item.id,
          link: item.categories_path + '/' + item.slug,
          img: getPreview(
            item.get_post_types,
            item.announce_img,
            null,
            true,
            moment(dateArticle)
          ),
          date: moment(dateArticle).format('DD.MM.YYYY'),
          title: item.title,
          tag: item.exclusive_material && 'Эксклюзив',
        };

        data.push(object);
      }
    });
  }

  return data;
});
