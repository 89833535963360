import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getMaterialsLoadMoreRequest = (payload: {
  page: number;
  offset: number;
}) => {
  // const url = prefixApiUrl + 'api/post/index///1/5';

  let { page, offset } = payload;

  let pageCurrent = 1;

  if (page) {
    pageCurrent = page;
  }

  // const url =
  //     prefixApiUrl +
  //     'api/taxonomy/7/posts/created_at/DESC/' +
  //     pageCurrent +
  //     '/3';

  const url =
    prefixApiUrl +
    'api/taxonomyitem/postsbyslugoffsetlimit/type-content/article/content/publication_at/DESC/' +
    offset +
    '/6';

  return new Promise((resolve, reject) => {
    const headers = {
      // Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      });
  });
};
