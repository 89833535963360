import { prefixApiUrl, token } from 'config';
import { error } from 'console';
import fetch from 'isomorphic-fetch';
import { reject } from 'lodash';

export const getArticleRequest = (payload: any) => {
  const slug: any = payload.slug;
  const id: any = payload.id;
  let url = prefixApiUrl + 'api/post/find/' + slug;

  return new Promise(resolve => {
    const headers = {
      Authorization: 'Bearer ',
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    fetch(url, {
      method: 'GET',
      headers,
    })
      .then(response => response.json())
      .then(data => {
        resolve(data);
      })
      .catch(error => reject(error));
  });
};
