import React, { useEffect } from 'react';
import './Promo.css';
import cx from 'classnames';
import { Tag } from 'components';
type Props = {
  type: string;
  img: string;
  tag: string;
  link: string;
  html: string;
  id: string;
  target?: string;
};

declare global {
  interface Window {
    ga?: any;
    gtag?: any;
  }
}

export function Promo(props: Props) {
  const { type, img, tag, link, html, id, target } = props;

  let handleClick = (idBanner: any) => {
    window.gtag &&
      window.gtag('event', 'banner', {
        action: 'click',
        id: target || idBanner,
      });
    window.ga &
      window.ga('send', 'event', 'banner', 'click', target || idBanner);
  };

  useEffect(() => {
    window.gtag &&
      window.gtag('event', 'banner', {
        action: 'view',
        id: target || id,
      });
    window.ga && window.ga('send', 'event', 'banner', 'view', target || id);
  }, []);

  return (
    <div
      className={cx(
        'promo',
        type && type === 'top' && 'promo--top',
        type && type === 'bottom' && 'promo--bottom',
        type && type === 'articles' && 'articles__promo',
        type && type === 'article-page' && 'page-article__promo',
        type && type === 'other' && 'other__promo',
        type && type === 'about' && 'about-project__promo'
      )}
    >
      {(html && html !== '' && <>{html}</>) || (
        <a
          href={link}
          target="_blank"
          className={cx(id)}
          onClick={e => handleClick(id)}
        >
          <img className="promo__img" src={img} alt="#" />
        </a>
      )}
      {/* {tag && (
            <div className="tag tag--white tag--mobile">Реклама</div>
            )} */}
    </div>
  );
}
