import * as React from 'react';
import Helmet from 'react-helmet';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonical?: string;
  status: string;
};

const cutTags = (text: string = '') => {
  return text.replace(/<\/?.+?>/gi, '');
};

const prepareData = ({
  title,
  description,
  image,
  url,
  canonical,
  status,
}: Props) => {
  return {
    title: title && cutTags(title),
    description: description && cutTags(description).substr(0, 250),
    image,
    url,
    canonical,
    status,
  };
};

function PageMeta(props: Props) {
  const { title, description, image, url, canonical, status } = prepareData(
    props
  );

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={canonical ? canonical : url} />
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="twitter:title" content={title} />
      {status === 'hidden' && <meta name="robots" content="noindex" />}
      {status === 'hidden' && <meta name="googlebot" content="noindex" />}
      {status === 'hidden' && (
        <meta name="yandex" content="noindex, nofollow" />
      )}
      {description && <meta name="description" content={description} />}
      {description && <meta property="og:description" content={description} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      {image && <meta property="og:image" content={image} />}
    </Helmet>
  );
}

PageMeta.defaultProps = {
  title: 'Site',
  description: null,
  image: null,
  url: '',
};

export { PageMeta };
