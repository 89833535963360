import { getToken, prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const addSubscribeRequest = (payload: {
  categories: any;
  email: string;
}) => {
  let { categories, email } = payload;

  let url = prefixApiUrl + 'api/subscribe';

  let headers: {} = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (getToken()) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + getToken(),
    };
  }

  let body = {
    categories: categories,
    email: email,
  };

  return fetch(url, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(body),
  })
    .then(response => response.text())
    .then(data => {
      return data;
    })
    .catch(error => {
      // console.log('error', error)
    });
};
