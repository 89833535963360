import { createActions } from 'redux-actions';

const {
  getNewsfeed,
  getNewsfeedSuccess,
  getNewsfeedFailure,
  getNewsfeedIdle,
}: {
  getNewsfeed?: any;
  getNewsfeedSuccess?: any;
  getNewsfeedFailure?: any;
  getNewsfeedIdle?: any;
} = createActions(
  'GET_NEWSFEED',
  'GET_NEWSFEED_SUCCESS',
  'GET_NEWSFEED_FAILURE',
  'GET_NEWSFEED_IDLE'
);

export { getNewsfeed, getNewsfeedSuccess, getNewsfeedFailure, getNewsfeedIdle };
