import { createSelector } from 'reselect';

import { Post, PostMetaItem, PostTaxonomyItem } from 'types/models';

import { getMetaField, getPreview } from 'config';
import moment from 'moment';

const materialsLoadMore = (state: {
  homepage: { materialsLoadMore: Post[] };
}) => state.homepage.materialsLoadMore;

export const materialsLoadMoreSelector = createSelector(
  [materialsLoadMore],
  data => {
    let arraySelector: any[] = [];

    if (data && data.length) {
      data.map((item: Post, index: number) => {
        if (item) {
          let dateArticle = item.created_at;
          if (item.publication_at) {
            dateArticle = item.publication_at;
          }

          let object = {
            id: item.id,
            link: item.categories_path + '/' + item.slug,
            date: moment(dateArticle).format('DD.MM.YYYY'),
            img: getPreview(
              item.get_post_types,
              item.announce_img,
              null,
              true,
              moment(dateArticle)
            ),
            title: item.title,
            tag: item.exclusive_material && 'Эксклюзив',
          };

          arraySelector.push(object);
        }
      });
    }

    return arraySelector;
  }
);
