import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const setViewRequest = (payload: any) => {
  const { id, hash }: any = payload;
  let url = prefixApiUrl + 'api/post-view';

  const headers = {
    Authorization: 'Bearer ',
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  let body = {
    hash: hash,
    post_id: id,
  };

  return fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })
    .then(response => response.json())
    .then(data => {});
};
