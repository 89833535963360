import * as React from 'react';

import { useSelector } from 'react-redux';

import * as bem from 'b_';
import { FooterInfo, FooterMenu, FooterSocial } from 'components';
import './Footer.css';
import { menuSelector } from 'store/ducks/menu/selector';
import { MenuItem } from 'types/models';
import { Link } from 'react-router-dom';

const b = bem.with('footer');

export function Footer() {
  const currentYear = new Date().getFullYear();

  const menu = useSelector(menuSelector);

  return (
    <footer className={b()}>
      <div className="footer__content container">
        <FooterInfo />
        <div className="footer-menu footer__menu">
          <h4 className="footer-menu__title">Темы</h4>
          <ul className="footer-menu__list">
            {menu.visible &&
              menu.visible.length &&
              menu.visible.map((item: MenuItem, index: number) => {
                return (
                  <li className="footer-menu__item" key={index}>
                    <Link to={item.slug} className="footer-menu__link">
                      {item.title}
                    </Link>
                  </li>
                );
              })}
            {menu.hidden &&
              menu.hidden.length > 0 &&
              menu.hidden.map((item: MenuItem, index: number) => {
                return (
                  <li className="footer-menu__item" key={index}>
                    <Link to={item.slug} className="footer-menu__link">
                      {item.title}
                    </Link>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="footer-menu footer__menu footer__menu--project">
          <h4 className="footer-menu__title">о проекте</h4>
          <ul className="footer-menu__list">
            <li className="footer-menu__item">
              <Link to="/magazine/about" className="footer-menu__link">
                Миссия
              </Link>
            </li>
            {/* <li className="footer-menu__item">
                            <a
                                href="./about-project.html"
                                className="footer-menu__link"
                            >
                                Контакты
                            </a>
                        </li> */}
            <li className="footer-menu__item">
              <Link
                to="/magazine/redaction/policy"
                className="footer-menu__link"
              >
                Редполитика
              </Link>
            </li>
            <li className="footer-menu__item">
              <Link to="/magazine/redaction" className="footer-menu__link">
                Редакция
              </Link>
            </li>
            <li className="footer-menu__item">
              <a
                href="/files/mediakit.pdf"
                target="_blank"
                className="footer-menu__link"
              >
                Партнерам
              </a>
            </li>
            {/* <li className="footer-menu__item">
                            <Link
                                to="/magazine/authors"
                                className="footer-menu__link"
                            >
                                Авторы
                            </Link>
                        </li> */}
            {/* <li className="footer-menu__item">
                            <a
                                href="#"
                                target="_blank"
                                className="footer-menu__link"
                            >
                                Партнерам
                            </a>
                        </li> */}

            {/* <li className="footer-menu__item">
                            <a
                                href="./tvchannels.html"
                                className="footer-menu__link"
                            >
                                Телеканалы
                            </a>
                        </li> */}
            {/* <li className="footer-menu__item">
                            <a
                                href="./telecalender.html"
                                className="footer-menu__link"
                            >
                                Телекалендарь
                            </a>
                        </li> */}
            <li className="footer-menu__item">
              <a
                href="/files/sout2018.pdf"
                target="_blank"
                className="footer-menu__link"
              >
                Оценка рабочих мест
              </a>
            </li>
            <li className="footer-menu__item">
              <a
                href="/files/politicsPersInfo.pdf"
                target="_blank"
                className="footer-menu__link"
              >
                Политика обработки и защиты персональных данных
              </a>
            </li>
            <li className="footer-menu__item">
              <a
                href="/files/politicsConf.pdf"
                target="_blank"
                className="footer-menu__link"
              >
                Политика конфеден-
                <br />
                циальности
              </a>
            </li>
            <li className="footer-menu__item">
              <a
                href="/files/requirementsImplInfo.pdf"
                target="_blank"
                className="footer-menu__link"
              >
                Сведения о реализуемых требованиях
              </a>
            </li>
          </ul>
        </div>
        <FooterSocial />
      </div>
      <a href="https://webmaster.yandex.ru/siteinfo/?site=https://telesputnik.ru">
        <img
          width="88"
          height="31"
          alt=""
          src="https://yandex.ru/cycounter?https://telesputnik.ru&theme=light&lang=ru"
        />
      </a>
    </footer>
  );
}
