import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';
import { type } from 'os';

export const getMaterialsRequest = (payload: {
  page: number;
  query: string;
  offset: string;
  sort_column: string;
}) => {
  let { page, query, offset, sort_column } = payload;

  let count = 3;

  let sort_order = 'DESC';

  if (sort_column == 'title') {
    sort_order = 'ASC';
  }

  let payloadSearch = {
    query: query,
    offset: offset,
    per_page: (page == 1 && 12) || 3,
    sort_column: sort_column,
    sort_order: sort_order,
  };

  if (page == 1) {
    count = 12;
  } else {
    page = page + 4;
  }

  const headers = {
    // Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (query == '') {
    let url =
      prefixApiUrl +
      'api/taxonomy/7/posts/' +
      sort_column +
      '/' +
      sort_order +
      '/' +
      page +
      '/' +
      count;

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'GET',
        headers,
      })
        .then(response => response.json())
        .then(data => {
          resolve(data);
        });
    });
  } else {
    // let url = prefixApiUrl + 'api/search-elastic';
    let url = prefixApiUrl + 'api/search';

    return new Promise((resolve, reject) => {
      fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(payloadSearch),
      })
        .then(response => response.json())
        .then(data => {
          // console.log(data);
          resolve(data);
        });
    });
  }
};
