import {
  AboutActionTypes,
  FETCH_ABOUT,
  FETCH_ABOUT_FAILURE,
  FETCH_ABOUT_SUCCESS,
} from './types';
// import { Sneakers } from 'types';
import produce, { Draft } from 'immer';

export interface AboutState {
  readonly data: any[];
  readonly isLoading: boolean;
  readonly error?: string;
}

export const initialState: AboutState = {
  data: [],
  isLoading: false,
  error: undefined,
};

export default produce(
  (draft: Draft<AboutState> = initialState, action: AboutActionTypes) => {
    switch (action.type) {
      case FETCH_ABOUT:
        draft.isLoading = true;
        draft.error = undefined;
        return;
      case FETCH_ABOUT_SUCCESS:
        draft.data = action.payload;
        draft.isLoading = false;
        draft.error = undefined;
        return;
      case FETCH_ABOUT_FAILURE:
        draft.data = initialState.data;
        draft.isLoading = false;
        draft.error = action.payload;
        return;
    }
    return draft;
  }
);
