import React, { useEffect, useState } from 'react';
import './ScrollTop.css';

export default function ScrollTop() {
  const [visibleScrollTop, setVisibleScrollTop] = useState(false);

  const handleScrollTop = (e: any) => {
    if (window.scrollY > window.innerHeight) {
      setVisibleScrollTop(true);
    } else {
      setVisibleScrollTop(false);
    }
  };

  const scrollToTop = (e: any) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScrollTop, false);
    return () => {
      window.removeEventListener('scroll', handleScrollTop, false);
    };
  }, []);

  return (
    <a
      href="#"
      className={`scroll-top ${visibleScrollTop ? '_show' : ''}`}
      onClick={scrollToTop}
    >
      <img src="/img/icons/icon-scroll-top.svg" />
    </a>
  );
}
