import { call, takeEvery, put, all, fork } from 'redux-saga/effects';

import { getMenuRequest, getSubmenuRequest } from './api';

import { getNavbar, getNavbarFailure, getNavbarSuccess } from './actions';

import { PostTaxonomyItem } from 'types/models';

function* getSaga() {
  try {
    const [menu, submenu]: [
      PostTaxonomyItem[],
      PostTaxonomyItem[]
    ] = yield all([call(getMenuRequest), call(getSubmenuRequest)]);

    yield put(
      getNavbarSuccess({
        menu,
        submenu,
      })
    );
  } catch (error) {
    // console.log(error);
    yield put(getNavbarFailure(error));
  }
}

export function* navbarWatcher() {
  yield takeEvery(getNavbar.toString(), getSaga);
}
