import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getArticlesRequest = () => {
  const url =
    prefixApiUrl +
    'api/taxonomyitem/postsbyslugoffsetlimit/type-content/article/content/publication_at/DESC/0/5';

  const headers = {
    // Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => data.posts);
};
