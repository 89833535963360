import { prefixApiUrl, token } from 'config';
import fetch from 'isomorphic-fetch';

export const getTaxonomyItemsPostRequest = (id: number) => {
  const url = prefixApiUrl + 'api/post/taxonomyitems/' + id + '/5';

  const headers = {
    Authorization: 'Bearer ' + token,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  return fetch(url, {
    method: 'GET',
    headers,
  })
    .then(response => response.json())
    .then(data => data);
};
