import { handleActions } from 'redux-actions';
import { combineReducers } from 'redux';
import { getNavbar, getNavbarIdle, getNavbarSuccess } from './actions';
import { NavbarState } from './types';
import { StateStatus, PostTaxonomyItem } from 'types/models';

export const initialState: NavbarState = {
  status: 'IDLE',
  menu: [],
  submenu: [],
};

const status = handleActions<StateStatus>(
  {
    [getNavbar]: () => 'LOADING',
    [getNavbarSuccess]: () => 'SUCCESS',
    [getNavbarIdle]: () => 'IDLE',
  },
  initialState.status
);

const menu = handleActions(
  {
    [getNavbarSuccess]: (
      state,
      action: { payload: { menu: PostTaxonomyItem[] } }
    ) => action.payload.menu,
  },
  initialState.menu
);

const submenu = handleActions(
  {
    [getNavbarSuccess]: (
      state,
      action: { payload: { submenu: PostTaxonomyItem[] } }
    ) => action.payload.submenu,
  },
  initialState.submenu
);

export const navbar = combineReducers({
  status,
  menu,
  submenu,
});
