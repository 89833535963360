import { createActions } from 'redux-actions';

const {
  getNewsfeeds,
  getNewsfeedsSuccess,
  getNewsfeedsFailure,
  getNewsfeedsIdle,
  getNewsfeedsClear,
}: {
  getNewsfeeds?: any;
  getNewsfeedsSuccess?: any;
  getNewsfeedsFailure?: any;
  getNewsfeedsIdle?: any;
  getNewsfeedsClear?: any;
} = createActions(
  'GET_NEWSFEEDS',
  'GET_NEWSFEEDS_SUCCESS',
  'GET_NEWSFEEDS_FAILURE',
  'GET_NEWSFEEDS_IDLE',
  'GET_NEWSFEEDS_CLEAR'
);

export {
  getNewsfeeds,
  getNewsfeedsSuccess,
  getNewsfeedsFailure,
  getNewsfeedsIdle,
  getNewsfeedsClear,
};
